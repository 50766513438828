import { Component, ChangeDetectorRef } from "@angular/core";
import { NbLoginComponent, NbAuthService } from "@nebular/auth";
import { Router } from "@angular/router";
import { AuthService } from "../service/auth.service";
import {
  NbToastrService,
  NbGlobalPhysicalPosition,
  NbComponentStatus,
  NbToastrConfig,
  NbGlobalPosition,
} from "@nebular/theme";

@Component({
  selector: "ngx-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent extends NbLoginComponent {
  config: NbToastrConfig;

  index = 1;
  destroyByClick = true;
  duration = 2000;
  hasIcon = true;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  preventDuplicates = true;
  status: NbComponentStatus = "primary";

  title = "HI there!";
  content = `I'm cool toaster!`;
  public mobNo: string;
  public otp: number;
  public isOtpSend: boolean = false;
  public buttonLabel: string = "Send Otp";
  constructor(
    service: NbAuthService,
    public authService: AuthService,
    cd: ChangeDetectorRef,
    router: Router,
    private toastrService: NbToastrService
  ) {
    super(service, {}, cd, router);
  }
  login() {
    if (!this.isOtpSend)
      this.authService.doLogin(this.mobNo).subscribe((res) => {
        console.log(res);
        if (res.code === 200) {
          this.isOtpSend = true;
          this.buttonLabel = "Verify Otp";
          this.showToast("success", "Success", "OTP Sent");
        } else {
          this.showToast("danger", "Error", res.status.displayableMessage);
        }
      });
    else
      this.authService.verifyOTP(this.mobNo, this.otp).subscribe((res) => {
        console.log(res);
        if (res.code === 200) {
          console.log(res);
          localStorage.setItem("currentUser", JSON.stringify(res.data));
          console.log(localStorage.getItem("currentUser"));
          this.router.navigateByUrl("/pages/dashboard");
          this.showToast("success", "Success", "Login Successfully");
        } else {
          this.showToast("danger", "Error", res.status.displayableMessage);
        }
      });
  }
  resendOtp() {
    this.authService.resendOTP(this.mobNo).subscribe((res) => {
      if (res.code === 200) {
        this.showToast("success", "Success", "OTP Sent");
      } else {
        this.showToast("danger", "Error", res.status.displayableMessage);
      }
    });
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  private showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      destroyByClick: this.destroyByClick,
      duration: this.duration,
      hasIcon: this.hasIcon,
      position: this.position,
      preventDuplicates: this.preventDuplicates,
    };
    const titleContent = title ? `${title}` : "";

    this.index += 1;
    this.toastrService.show(body, `${titleContent}`, config);
  }
}
