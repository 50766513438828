import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import {
  NbMediaBreakpointsService,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
} from "@nebular/theme";

import { UserData } from "../../../@core/data/users";
import { LayoutService } from "../../../@core/utils";
import { map, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { Router } from "@angular/router";
import { AuthService } from "../../../auth/service/auth.service";
import { FormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
@Component({
  selector: "ngx-header",
  styleUrls: ["./header.component.scss"],
  templateUrl: "./header.component.html",
})
export class HeaderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  @Input("deviceDropDwonDisble") deviceDropDwonDisble: boolean = true;

  @Output("ondeviceChange")
  onDeviceChangeCallback: EventEmitter<any> = new EventEmitter<any>();
  userPictureOnly: boolean = false;
  user: any;

  public selectedDevice: string = "android";

  themes = [
    {
      value: "default",
      name: "Light",
    },
    {
      value: "dark",
      name: "Dark",
    },
    {
      value: "cosmic",
      name: "Cosmic",
    },
    {
      value: "corporate",
      name: "Corporate",
    },
  ];

  currentTheme = "dark";

  userMenu = [
    //  { title: "Profile" },
    // { title: "Log out" },
  ];
  name: any;

  constructor(
    public router: Router,
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private userService: UserData,
    private authService: AuthService,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService
  ) {
    menuService.onItemClick().subscribe((data) => {
      if (data.item.title === "Log out") {
        localStorage.clear();
        this.navigateHome();
      }
    });
  }

  ngOnInit() {
    // this.currentTheme = this.themeService.currentTheme;
    this.name = this.authService.getCurrentUser().fullName;
    this.changeTheme(this.currentTheme);
    // this.userService
    //   .getUsers()
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe((users: any) => (this.user = users.nick));

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService
      .onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$)
      )
      .subscribe(
        (isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl)
      );

    this.themeService
      .onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$)
      )
      .subscribe((themeName) => (this.currentTheme = themeName));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, "menu-sidebar");
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    // this.menuService.navigateHome();
    this.router.navigate(["/pages"]);
    return false;
  }
  deviceChange() {
    this.onDeviceChangeCallback.emit();
  }
}
