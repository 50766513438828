import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { NbAuthService } from "@nebular/auth";
import { tap } from "rxjs/operators";
import { AuthService } from "./service/auth.service";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate() {
    // return this.authService.isAuthenticated()
    console.log("auth", this.authService.isAuthenticated());
    if (!this.authService.isAuthenticated()) {
      this.router.navigate(["auth/login"]);
    }
    return true;
    // })
    // );
  }
}
