import { Injectable } from "@angular/core";
import { NbAuthService, NbTokenService } from "@nebular/auth";
import { BehaviorSubject } from "rxjs";
import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/map";
import "rxjs/add/observable/of";
import { Subject } from "rxjs/Subject";
import "rxjs/add/operator/toPromise";
import "rxjs/Rx";
import { ResponseData } from "../../core/modal/base-save-update-response";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  constructor(private http: HttpClient, private router: Router) {}
  isAuthenticated() {
    // return Observable.combineLatest().map(results => {
    localStorage.setItem(
      "currentUser",
      '{"fullName":"Harshad Akhani","mobileNo":"9722118512","userRole":"Developer","assignedModules":["USER_GROWTH_METRIC","TRANSACTION_GROWTH_METRIC","FIREBASE_ANALYTICS_DATA","TRAFFIC_MIX_AND_CONVERSION_FUNNEL","NEW_USER_DETAILS","SALE","SOCIAL_AND_SHARING_DETAILS","CONTRIBUTION_METRICS","RETENTION_COHORTS","Quick_View","Daily_Metrics","OverAll_Article_Performance","Category_Level_Performance"]}'
    );
    console.log(localStorage.getItem("currentUser"));
    if (localStorage.getItem("currentUser")) {
      return true;
    } else {
      return false;
    }
    // });
  }
  public doLogin(mobileNumber): Observable<ResponseData<any>> {
    let misUserDataString = { mobileNo: "", appVersion: environment.version };
    let header = {
      headers: {
        misUserDataString: JSON.stringify(misUserDataString),
      },
    };

    return this.http.post<ResponseData<any>>(
      environment.ACL_URL + "requestOTP?mobileNo=" + mobileNumber,
      {},
      header
    );
  }
  public verifyOTP(mobileNo, otp: Number): Observable<ResponseData<any>> {
    let misUserDataString = {
      mobileNo: mobileNo,
      appVersion: environment.version,
    };
    let header = {
      headers: {
        misUserDataString: JSON.stringify(misUserDataString),
      },
    };
    return this.http.post<ResponseData<any>>(
      environment.ACL_URL + "verifyOTP?otp=" + otp,
      // environment.SERVER_URL + "verifyOTP",
      {},
      header
    );
  }
  public resendOTP(mobileNumber): Observable<ResponseData<any>> {
    let misUserDataString = { mobileNo: "", appVersion: environment.version };
    let header = {
      headers: {
        misUserDataString: JSON.stringify(misUserDataString),
      },
    };
    return this.http.post<ResponseData<any>>(
      environment.ACL_URL + "resendOTP?mobileNo=" + mobileNumber,
      {},
      header
    );
  }
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    // localStorage.clear();
    // this.currentUserSubject.next(null);
    this.router.navigate(["/auth/login"]);
  }

  getCurrentUser() {
    let obj = JSON.parse(localStorage.getItem("currentUser"));
    return obj;
  }
}
